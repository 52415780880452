<template>
  <div>
    <!-- 添加标签 -->
    <el-dialog
      :title="
        labelArr && labelArr.length > 0
          ? $t('label.tag.edittags')
          : $t('label.tag.addtags')
      "
      :visible.sync="dialogVisible"
      top="15%"
      width="373px"
      :modal-append-to-body="false"
      class="dialog"
      :before-close="close"
    >
      <div>
        <div class="tag" v-if="exhibition.length > 0 ? true : false">
          <el-tag
            v-for="(tag, index) in exhibition"
            :key="tag.name"
            :style="{
              background:
                tag.color === 'hong'
                  ? hong
                  : tag.color === 'huang'
                  ? huang
                  : tag.color === 'lan'
                  ? lan
                  : tag.color === 'hui'
                  ? hui
                  : tag.color === 'lv'
                  ? lv
                  : '',
              color: color,
            }"
            :type="tag.type"
            @click="removeTag(tag, index)"
          >
            {{ tag.name }}
            <i class="el-icon-close"></i>
          </el-tag>
        </div>
        <!-- 请输入内容 -->
        <el-input
          v-model="input"
          @input="querySearchAsync"
          :placeholder="$t('label_tabpage_contentz')"
          @focus="focus"
          :fetch-suggestions="querySearchAsync"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button size="mini" @click="dialogVisible = false">
          {{ $t('button_partner_cancel') }}
        </el-button>
        <!-- 确定 -->
        <el-button size="mini" type="primary" @click="submit">
          {{ $t('label.ems.confirm') }}
        </el-button>
      </span>
      <el-card class="box-card" v-if="isCard" v-loading="loading">
        <ul>
          <li class="li" v-for="(tag, index) in tags" :key="tag.id">
            <el-tag
              @click="colorBtn(tag, index)"
              :style="{
                background:
                  tag.color === 'hong'
                    ? hong
                    : tag.color === 'huang'
                    ? huang
                    : tag.color === 'lan'
                    ? lan
                    : tag.color === 'hui'
                    ? hui
                    : tag.color === 'lv'
                    ? lv
                    : '',
                color: color,
              }"
              style="cursor: pointer"
            >
              {{ tag.name }}
            </el-tag>
          </li>
        </ul>
      </el-card>
      <el-card class="box-card box-cards" v-if="isNewTag">
        <div class="header">
          <!-- 新增标签 -->
          <span class="newtag"> {{ $t('label.tag.manage.newtag') }}:</span>
          <span class="newtag left">{{ input }}</span>
          <div style="margin-left: 38px; margin-top: 20px">
            <!-- 可见性 -->
            <span class="all"> {{ $t('label.customsetting.visible') }}:</span>
            <div class="radios">
              <!-- 公用 -->
              <el-radio v-model="radio" label="public" class="radio">
                {{ $t('label.customsetting.visible.public') }}</el-radio
              >
              <!-- 私用 -->
              <el-radio v-model="radio" label="private" class="radio">
                {{ $t('label.tag.addtags.private') }}</el-radio
              >
            </div>
            <div style="clear: both"></div>
          </div>
          <div style="margin-top: 17px; margin-left: 10px">
            <!-- 标签颜色 -->
            <span class="tagColor">
              {{ $t('label.tag.addtags.tagcolour') }}:</span
            >
            <div class="colors">
              <el-radio
                v-model="types"
                label="hong"
                class="hong"
                :disabled="isReadyonly == 1"
              ></el-radio>
              <el-radio
                v-model="types"
                label="huang"
                class="huang"
                :disabled="isReadyonly == 1"
              ></el-radio>
              <el-radio
                v-model="types"
                label="lv"
                class="lv"
                :disabled="isReadyonly == 1"
              ></el-radio>
              <el-radio
                v-model="types"
                label="lan"
                class="lan"
                :disabled="isReadyonly == 1"
              ></el-radio>
              <el-radio
                v-model="types"
                label="hui"
                class="hui"
                :disabled="isReadyonly == 2"
              ></el-radio>
            </div>
            <div style="clear: both"></div>
          </div>
          <span slot="footer" class="dialog-footer ft">
            <!-- 确定 -->
            <el-button @click="add" type="primary" size="mini">
              {{ $t('label.ems.confirm') }}</el-button
            >
            <!-- 取消 -->
            <el-button @click="close" size="mini">
              {{ $t('button_partner_cancel') }}</el-button
            >
          </span>
        </div>
      </el-card>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetTagSearchTags,
  GetTagAddTagRelation,
  GetTagDeleteTagRelationg,
  GetTagSaveTag,
  GetTagGetTagById,
  GetTagGetTagsByRecordId,
  GetTagGetManageTagPermission,
} from '@/views/marketList/api.js'
export default {
  props: {
    ids: String,
    detail: String,
    objIds: {
      type: String,
    },
    labelArr: {
      type: Array,
    },
  },
  data() {
    return {
      objIdsD:this.objIds,
      isTag: false,
      isReadyonly: 2,
      dialogVisible: false,
      input: '',
      isCard: false,
      tags: [],
      onlyTypes: 'hui',
      color: '#fff',
      hong: '#F6817B',
      huang: '#FEC366',
      lan: '#62A5F3',
      hui: '#C8C6C6',
      lv: '#7cedcc',
      exhibition: [],
      restaurants: [],
      isNewTag: false,
      radio: 'private',
      types: 'hong',
      tages: [],
      loading: false,
      isManageTag: '',
      isCreatePrivateTag: '',
    }
  },
  methods: {
    //权限管理
    async manage() {
      let res = await GetTagGetManageTagPermission()
      this.isManageTag = res.data.isManageTag
      this.isCreatePrivateTag = res.data.isCreatePrivateTag
    },
    async focus() {
      this.manage()
      this.loading = true
      if (this.objIdsD === '' || this.objIdsD === undefined) {
        this.$message.info(this.$i18n.t('message.error.SQLTimeout'))
        this.isShows()
        this.loading = false
      } else {
        this.GetTagSearchTagss()
        if (this.$route.params.oprateType === 'DETAIL') {
          let params = {
            objId: this.objIdsD,
            recordId: this.ids,
          }
          let res = await GetTagGetTagsByRecordId(params)
          if (res.result) {
            this.loading = false
            this.tages = res.data
            // this.exhibition = res.data;
          }
        } else if (this.$route.name === '分屏页') {
          let params = {
            objId: this.objIdsD,
            recordId: this.ids,
          }
          let res = await GetTagGetTagsByRecordId(params)
          if (res.result) {
            this.loading = false
            this.tages = res.data
            // this.exhibition = res.data;
          }
        }
      }
    },
    async GetTagSearchTagss() {
      let params = {
        objId: this.objIdsD,
      }
      let res = await GetTagSearchTags(params)
      if (res.result) {
        this.isCard = true
        this.loading = false
        let arr1Ids = this.tages.map((item) => item.name)
        let result = res.data.filter((item) => !arr1Ids.includes(item.name))
        this.tags = result
      }
    },
    async colorBtn(item) {
      this.tags.forEach((items, index) => {
        if (items.id === item.id) {
          this.tags.splice(index, 1)
        }
      })
      this.input = ''
      this.exhibition.push(item)
      let params = {
        objId: this.objIdsD,
        tagId: item.id,
        recordIds: this.ids,
      }
      await GetTagAddTagRelation(params)
    },
    async removeTag(tag, index) {
      let params = {
        objId: this.objIdsD,
        tagId: tag.tagid,
        recordIds: this.ids,
      }
      await GetTagDeleteTagRelationg(params)
      this.tags.unshift(tag)
      this.exhibition.splice(index, 1)
    },
    submit() {
      if (this.detail === 'detail') {
        this.$emit('updateDelLabelArr', this.exhibition)
        this.isShows()
        this.tags = []
        this.exhibition = []
        this.isNewTag = false
      } else {
        this.$parent.addTag()
        this.$parent.refresh()
      }
    },
    async querySearchAsync(queryString) {
      if (queryString !== '') {
        let params = {
          objId: this.objIdsD,
          searchKeyWord: queryString,
        }
        let res = await GetTagSearchTags(params)
        if (res.result) {
          this.tags = res.data
        }
      } else {
        this.GetTagSearchTagss()
      }
    },
    createStateFilter(queryString) {
      return (input) => {
        return input.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },

    async add() {
      // if (this.isManageTag === "false" && this.isCreatePrivateTag === "false") {
      //   this.$message.info(this.$i18n.t("label.weixin.donot.permission"));
      //   this.input = "";
      //   this.isNewTag = false;
      //   return;
      // }
      let params = {
        objId: this.objIdsD,
        name: this.input,
        color: this.types,
        type: this.radio,
      }
      let res = await GetTagSaveTag(params)
      if (res.result) {
        this.isNewTag = false
        this.searchTag(res.data.id)
        this.input = ''
      } else {
        this.$message.error(this.$i18n.t('label.tag.manage.prompt'))
      }
    },
    async searchTag(id) {
      let paramss = {
        objId: this.objIdsD,
        id: id,
      }
      let res = await GetTagGetTagById(paramss)
      this.exhibition.push(res.data[0])
      let params = {
        objId: this.objIdsD,
        tagId: id,
        recordIds: this.ids,
      }
      await GetTagAddTagRelation(params)
    },
    close() {
      this.input = ''
      this.isNewTag = false
      this.dialogVisible = false
    },
    isShows() {
      this.dialogVisible = !this.dialogVisible
    },
    tagApi(objId) {
      this.objIdsD = objId
    },
    labelArrs(val) {
      if (val.length !== 0) {
        val.forEach((item) => {
          this.tags.forEach((items, index) => {
            if (item.tagid === items.id) {
              this.tags.splice(index, 1)
            }
          })
        })

        if (val.length === 0) {
          this.isTag = false
        } else {
          this.isTag = true
          this.exhibition = val
        }
      }
    },
  },
  watch: {
    ids() {},
    tags(val) {
      if (val.length == 0 && this.input !== '') {
        this.isCard = false
        this.isNewTag = true
      } else if (val.length == 0 && this.input === '') {
        this.isCard = false
        this.isNewTag = false
      } else {
        this.isCard = true
        this.isNewTag = false
      }
    },
    radio(value) {
      if (this.isManageTag === 'false' && this.isCreatePrivateTag === 'false') {
        this.$message.info(this.$i18n, this.$i18n.t('label.weixin.donot.permission'))
        this.input = ''
        this.isNewTag = false
        return
      }
      if (value === 'public') {
        if (this.isManageTag === 'false') {
          // 对不起，您没有权限创建公用标签
          this.$message.info(this.$i18n.t('message.nopermession'))
          this.radio = 'private'
          this.isReadyonly = 2
          this.types = 'hong'
          return
        }
        this.isReadyonly = 1
        this.types = 'hui'
      } else if (value === 'private') {
        if (this.isCreatePrivateTag === 'false') {
          // 对不起，您没有权限创建私用标签
          this.$message.info(this.$i18n.t('message.nopermession'))
          this.radio = 'public'
          this.isReadyonly = 1
          this.types = 'hui'
          return
        }
        this.isReadyonly = 2
        this.types = 'hong'
      }
    },
    exhibition(val) {
      val.forEach((item) => {
        this.tags.forEach((items, index) => {
          if (item.tagid === items.id) {
            this.tags.splice(index, 1)
          }
        })
      })

      if (val.length === 0) {
        this.isTag = false
      } else {
        this.isTag = true
      }
    },
    dialogVisible(val) {
      if (val === false) {
        this.exhibition = []
        this.tags = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tag {
  width: 100%;
  margin-bottom: 15px;

  .el-tag {
    margin-right: 6px;
    margin-top: 5px;
  }
}

.box-card {
  z-index: 2009;
  height: 137px;
  overflow-y: auto;

  ::v-deep .el-card__body {
    padding: 0;

    .li {
      margin: 5px 0 0 11px;

      .el-tag {
        line-height: 24px;
        height: 24px;
      }
    }
  }
}

::v-deep .el-tag__close {
  color: #fff;
}

.header {
  .newtag {
    display: inline-block;
    margin: 21px 0 0 24px;
    font-size: 14px;
  }

  .left {
    margin-left: 10px;
  }
}

.all {
  float: left;
}

.radios {
  float: left;
  margin-left: 20px;
  margin-top: 3px;

  ::v-deep .el-radio__inner {
    margin-right: 10px;
  }

  ::v-deep .el-radio__label {
    margin-left: -10px;
    font-size: 12px;
  }
}

::v-deep .tagColor {
  margin-right: 17px;
  height: 14px;
  font-size: 14px !important;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
  float: left;
  margin-left: 18px;
}

.colors {
  margin-top: 20px;

  ::v-deep .el-checkbox__label {
    display: none;
  }

  ::v-deep .el-radio__inner {
    width: 14px;
    height: 14px;
    border-radius: 0;
  }

  ::v-deep .el-radio {
    margin-right: 5px;
  }

  ::v-deep .el-radio__label {
    display: none;
  }

  ::v-deep .hong {
    .el-radio__inner {
      background: #f6817b;
      border: none;
    }
  }

  ::v-deep .huang {
    .el-radio__inner {
      background: #fec366;
      border: none;
    }
  }

  ::v-deep .hui {
    .el-radio__inner {
      background: #c8c6c6;
      border: none;
    }
  }

  ::v-deep .lan {
    .el-radio__inner {
      background: #62a5f3;
      border: none;
    }
  }

  ::v-deep .lv {
    .el-radio__inner {
      background: #7cedcc;
      border: none;
    }
  }
}

.box-cards {
  position: absolute;
  width: 300px;
  height: 190px;
}

.ft {
  float: right;
  margin: 16px 18px 0 0;
}

::v-deep .el-tag {
  height: 24px;
  line-height: 23px;
}
</style>
